import { classNames } from "repoV2/utils/common/render/classNames";
import React, { useRef, useState } from "react";
import { IPopup } from "./IPopup";
import styles from "./Popup.module.scss";

export const usePopup = ({
    closePopup,
    animationDuration = 250,
}: {
    closePopup: () => void;
    animationDuration?: number;
}) => {
    const [closing, setClosing] = useState(false);
    const closePopupWithAnimation = () => {
        setClosing(true);
        setTimeout(() => {
            closePopup();
        }, animationDuration);
    };

    return { animationDuration, closing, closePopupWithAnimation };
};

/**
 * ### Optional
 * To show closing animation when the popup closes (can be used only when `useDefaultContainerClass === true`).
 * 1. implement `usePopup` hook in your popup
 * 3. use `closePopupWithAnimation` instead of `closePopup`
 * 2. pass the props returned by `usePopup` hook to this component
 */
export const Popup = ({
    closePopup,
    children,
    containerClass,
    useDefaultContainerClass = true,
    closing = false,
    animationDuration = 250,
    classNames: classNamesProp,
}: IPopup.IProps): JSX.Element => {
    const popupRef = useRef<HTMLDivElement>(null);

    const handleClickOutside = (e: any) => {
        if (popupRef.current && !popupRef?.current?.contains(e.target)) {
            closePopup();
        }
    };

    return (
        <div
            className={classNames(styles.root, classNamesProp?.root)}
            onClick={handleClickOutside}
        >
            <div
                ref={popupRef}
                className={classNames(
                    containerClass,
                    useDefaultContainerClass && styles.container,
                    useDefaultContainerClass &&
                        (closing ? styles.closing : styles.opening)
                )}
                style={
                    useDefaultContainerClass
                        ? {
                              animationDuration: `${
                                  // added 1 to make the animation last a little longer than the closing timer
                                  (animationDuration + 1) / 1000
                              }s`,
                          }
                        : {}
                }
            >
                {children}
            </div>
        </div>
    );
};

export { MyClassAddressPopup } from "./MyClassAddressPopup";
export { BlogVisitorPopup } from "./BlogVisitorPopup";
export { SharePopup } from "./SharePopup";

import { getQueryParam } from "../../../../utils/urls&routing/urlParams";
import { isBrowser } from "../../../../utils/common/render/window";
import {
    COUNTRIES_DATA_LIST,
    INDIA_COUNTRY_DATA,
} from "../../../../utils/common/location/countriesData";
import {
    DEFAULT_TIMEZONE,
    DEFAULT_TIMEZONE_OBJECT,
    TIMEZONE_DATA_LIST,
    TIMEZONE_LOCAL_STORAGE_KEYS,
} from "./Timezone.constants";
import {
    getLocalStorageItem,
    setLocalStorageItem,
} from "../../../../utils/common/storage/getterAndSetters";

const getTimezone = () => {
    try {
        const timezone = new window.Intl.DateTimeFormat().resolvedOptions()
            .timeZone;

        // This is a bug in chromium that returns Calcutta instead of Kolkata
        // This function will just convert it to Asia/Kolkata if that is the case
        // Add any further normalisation of timezone parsing here
        return timezone === "Asia/Calcutta" ? DEFAULT_TIMEZONE : timezone;
    } catch (error) {
        return DEFAULT_TIMEZONE;
    }
};

/**
 * This function gets the system specific timezone string which is used to
 * fetch api details such as price details etc. which does not changes based on
 * user timezone selection
 * @returns returns system timezone string
 */
export const getSystemTimezone = (): string => {
    if (!isBrowser()) {
        return DEFAULT_TIMEZONE;
    }

    // Todo: This is a temporary thing to force india timezone, to take in page speed
    // stats (which google by default take on the international timezone) on indian timezone
    // This is tmeporary, so not creating constant for 'force_india_flag' here
    const isForceIndiaTimezone = getQueryParam("force_india_flag");

    if (isForceIndiaTimezone) {
        return DEFAULT_TIMEZONE;
    }
    const cachedTimezone = getLocalStorageItem(
        TIMEZONE_LOCAL_STORAGE_KEYS.SYSTEM_TIMEZONE
    );
    if (cachedTimezone) {
        return cachedTimezone;
    }

    const timezone: string = getTimezone();
    // @dev This key is added to decouple User Timezone from system timezone
    setLocalStorageItem(TIMEZONE_LOCAL_STORAGE_KEYS.SYSTEM_TIMEZONE, timezone);
    return timezone;
};

/**
 * to avoid redeclaration of `KNOWN_TIME_ZONES` on each call of `getIsKnownTimezone`, it is declared outside of function scope
 * move inside constants file if export of this const is needed
 */
const TIMEZONE_NAME_LIST = TIMEZONE_DATA_LIST.map(t => t.name);
export const getIsKnownTimezone = (timezone: string): boolean => {
    return TIMEZONE_NAME_LIST.includes(timezone);
};

/**
 * @dev
 * This function gets the user specific timezone which can be used to fetch the
 * user specific details eg. session details, which are render specific details
 * @returns user timezone string
 */
export const getUserTimezone = () => {
    // handle SSR api requests
    if (!isBrowser()) {
        return DEFAULT_TIMEZONE;
    }
    // Todo: This is a temporary thing to force india timezone, to take in page speed
    // stats (which google by default take on the international timezone) on indian timezone
    // This is tmeporary, so not creating constant for 'force_india_flag' here
    const isForceIndiaTimezone = getQueryParam("force_india_flag");
    if (isForceIndiaTimezone) {
        return DEFAULT_TIMEZONE;
    }

    const cachedUserTimezone = getLocalStorageItem(
        TIMEZONE_LOCAL_STORAGE_KEYS.USER_TIMEZONE
    );

    if (cachedUserTimezone) {
        return cachedUserTimezone;
    }
    return getTimezone();
};

/**
 * @dev
 * This function sets the user specific timezone which can be used to fetch the
 * user specific details eg. session details, which are render specific details
 * @returns user timezone string
 */
export const setUserTimezone = (data: { selectedUserTimezone: string }) => {
    const { selectedUserTimezone } = data;
    setLocalStorageItem(
        TIMEZONE_LOCAL_STORAGE_KEYS.USER_TIMEZONE,
        selectedUserTimezone
    );
};

// @dev get dial up code based on user timezone
export const getCountryDataBasedOnTimezone = ({
    timezone,
}: {
    timezone: string;
}) => {
    const { country_code = DEFAULT_TIMEZONE_OBJECT.country_code } =
        TIMEZONE_DATA_LIST.find(({ name }) => name === timezone) || {};
    const countryData = COUNTRIES_DATA_LIST.find(
        ({ code }) => code === country_code
    );
    return countryData;
};

// @dev get dial up code based on user timezone
export const getDefaultDialCodeFromUserTimezone = () => {
    const userTimezone = getSystemTimezone();
    const country =
        getCountryDataBasedOnTimezone({ timezone: userTimezone }) ||
        INDIA_COUNTRY_DATA;

    return country.dial_code;
};

/**
 * @dev
 * converts timezone offset in HHMM(eg: `"+0530"`) to HH:MM(eg: `"+05:30"`)
 */
export const formatTimezoneOffset = (offset: string) => {
    const regex = /(\d{2})(\d{2})/g; // Matches two-digit hours followed by two-digit minutes,i.e HHMM
    const replacedString = offset.replace(regex, "$1:$2");
    return replacedString;
};

/**
 * This util checks if the system of the user is on domestic timezone
 * @returns boolean - check if the timezone is domestic
 */
export const checkSystemTimezoneDomestic = () => {
    return getSystemTimezone() === DEFAULT_TIMEZONE;
};

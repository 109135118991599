// @ts-ignore
import { IMAGE_CDN_FORMATS as _IMAGE_CDN_FORMATS } from "@my-scoot/exly-react-component-lib/build/cjs/features/Common/modules/ExlyImage/constants/ExlyImage.constants";
import { IMAGE_CDN_FORMATS as _IMAGE_CDN_FORMATS_INTERFACE } from "@my-scoot/exly-react-component-lib/build/types/features/Common/modules/ExlyImage/constants/ExlyImage.constants";

export const IMAGE_OPTIMIZATION_CDN_URL =
    process.env.NEXT_PUBLIC_S3_IMAGES_CDN_URL || "";
export const NON_OPTIMIZATION_CDN_URL =
    process.env.NEXT_PUBLIC_S3_CDN_URL || "";
export const IMAGE_OPTIMIZATION_S3_BUCKET =
    process.env.NEXT_PUBLIC_S3_CDN_BUCKET_EXLY_CONTENT_IMAGES;
export const NON_OPTIMIZATION_S3_BUCKET =
    process.env.NEXT_PUBLIC_S3_CDN_BUCKET_EXLY_CONTENT;

export const IMAGES_PLUGINS = {
    PRECONNECT_CDN_URL: "p1",
    PRELOAD_LISTING_COVER_IMAGE: "p2",
    COVER_IMAGE_FETCH_PRIORITY_HIGH: "p3",
};

export const IMAGE_CDN_FORMATS: typeof _IMAGE_CDN_FORMATS_INTERFACE =
    _IMAGE_CDN_FORMATS;

/**
 * Part of the upload url pathname for the secion uploading the image
 * More Section to be added based on which section is the image uploaded from.\
 * Note:
 * - Keep it without spaces and special characters.
 * - Use hyphens(-) and underscore(_) as separators
 */
export const IMAGE_SECTION_KEY_PATH = {
    BRANDED_COMMUNITY: "branded_community",
    RECORDED_CONTENT: "recorded_content",
};

// TODO: Create proper type declaration for the express middleware props and remove the @ts-nocheck flag
import { IPage } from "@Interfaces";

import { getCreatorSubdomainFromUrlHost } from "repoV2/utils/urls&routing/domain";
import { getQueryParam as _getQueryParam } from "repoV2/utils/urls&routing/urlParams";
import { isEmpty, isBrowser } from "./common";
import { ApiCall } from "./api";
import { STORAGE_KEYS } from "./constants/keys";
import { createAction, ACTION_TYPES } from "./constants/actions";
import { APP_ROUTE_GENERATORS, ROUTES } from "./constants";
import { getLocalStorageItem } from "../repoV2/utils/common/storage/getterAndSetters";
import { appendQueryParams } from "./urls";

// Makes an API call to validate the domain's existence
const isDomainValid = async (hostName: string) => {
    const response: any = await ApiCall({
        url: `/host/domain/existency/${hostName}`,
    });
    return response?.data?.data || {};
};

export async function validSubDomain(
    { req, res, store }: IPage.IPageContext,
    additionalValidation: boolean = true
) {
    // There is a problem where getInitialProps runs only on the server
    // https://stackoverflow.com/questions/49415755/next-js-fetching-data-in-getinitialprops-server-side-vs-client-side

    const isInBrowser: boolean = !req && isBrowser();
    const host: string =
        (isInBrowser ? window?.location?.host : req?.headers?.host) || "";

    let redirectUrl: string | undefined;

    // tags: #test, #ngrok
    // kept here for running https server locally using ngrok
    // const hostName = "shubham" || getCreatorSubdomainFromUrlHost(host);
    const hostName = getCreatorSubdomainFromUrlHost(host) || "";
    const url = req?.url || "";
    // `/serviceLogin` and `/telegram_oauth` is only allowed for subdomain=auth, will give error for any other pathname
    // Routes `/googleAuth` and `/googleAuthRedirect` are used for logging in with Google OAuth.
    if (
        hostName === process.env.NEXT_PUBLIC_LOGIN_SUBDOMAIN &&
        (url.includes(ROUTES.SERVICELOGIN.route) ||
            url.includes(APP_ROUTE_GENERATORS.TELEGRAM_OAUTH()))
    ) {
        return hostName;
    } else if (
        typeof hostName !== undefined &&
        hostName !== "undefined" &&
        hostName &&
        additionalValidation
    ) {
        const isDomainValidResponse = await isDomainValid(hostName);
        const existency: boolean | undefined = isDomainValidResponse?.existency;
        const redirect: string | undefined = isDomainValidResponse?.redirect;

        if (existency === true) {
            store.dispatch(
                createAction(ACTION_TYPES.HOST.SET_HOST, {
                    hostName,
                })
            );
            return hostName;
        }
        if (!isEmpty(redirect)) {
            // The rest of the URL containing the paths and URL params
            const urlSuffix = isInBrowser ? window?.location?.href : url;
            // TODO: Types for this

            redirectUrl = `//${redirect}.${process.env.NEXT_PUBLIC_PROJECT_DOMAIN}${urlSuffix}`;
        }
    }

    // none of the positive cases have passed... need to redirect
    redirectUrl =
        redirectUrl ||
        (process.env.NEXT_PUBLIC_PROJECT_DOMAIN
            ? `//${process.env.NEXT_PUBLIC_PROJECT_DOMAIN}`
            : "https://exlyapp.com");

    // TODO: separate redirection logic from `validSubDomain` function
    if (res) {
        res.writeHead?.(307, { Location: redirectUrl });
        res.end?.();
    }
    return null;
}
/**
 * @deprecated use `getQueryParam` in `repoV2/utils/urls&routing/urlParams.ts`
 */
export const getQueryParam = _getQueryParam;

export const getLoginNextUrl = (nextURL?: string) => {
    // If the login is done by the creator and the nextURL is not present,
    // redirect the creator to the recorded content page by default
    const isCreator = getLocalStorageItem(STORAGE_KEYS.IS_CREATOR) === true;
    const defaultNextPage = isCreator
        ? ROUTES.CONTENT.urlPath
        : ROUTES.MY_CLASSES.urlPath;

    let computedNextUrl = decodeURIComponent(nextURL || defaultNextPage);

    if (computedNextUrl.includes(process.env.NEXT_PUBLIC_EXLY_CON_DOMAIN!)) {
        computedNextUrl = appendQueryParams(computedNextUrl, {
            userId: getLocalStorageItem(STORAGE_KEYS.CUSTOMER_UUID),
            from: encodeURIComponent(`${window.location.origin}/my_bookings`),
            userEmail: getLocalStorageItem(STORAGE_KEYS.EMAIL),
            userName: getLocalStorageItem(STORAGE_KEYS.CUSTOMER_NAME),
        });
    }
    return computedNextUrl;
};
